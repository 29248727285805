import React from "react"
import { FaGithub } from 'react-icons/fa'

import { StaticImage } from "gatsby-plugin-image"

import { motion } from "framer-motion"

import socialLinks from "../constants/social_links"

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div>
          <div className="footer-link social-links" style={{justifyContent: 'center'}}>
            <motion.a
              key="githubProfile"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.4}}
              href="https://github.com/conka991"
              target="_blank"
              rel="nofollow"
              className="social-link"
            >
              <FaGithub  />
            </motion.a>
            {socialLinks.map((link, index) => {
              return (
                <motion.a
                  key={link.id}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.5 + (index * 0.2)}}
                  href={link.url}
                  target="_blank"
                  rel="nofollow"
                  className="social-link"
                >
                  {link.icon}
                </motion.a>
              )
            })}
          </div>
          <div>
            <h4>Powered by</h4>
            <div style={{margin: '1rem', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center', gap: '1rem'}}>
              <a href="http://gatsbyjs.com" target="_blank" rel="noreferrer">
                <StaticImage src='../assets/images/gatsby-logo-white.png' alt="gatsby logo" height={36} layout="fixed" placeholder="blurred" />
              </a>
              <a href="http://netlify.com" target="_blank" rel="noreferrer">
                <StaticImage src='../assets/images/netlify-logo-white.png' alt="netlify logo" height={36} layout="fixed" placeholder="blurred" />
              </a>
            </div>
          </div>
          <div>
            <h4>Copyright &copy; {new Date().getFullYear()}</h4>
            <h4>All rights reserved</h4>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
