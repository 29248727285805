const data = [
  {
    id: 1,
    text: "Inicio",
    url: "/",
  },
  {
    id: 2,
    text: "Sobre mi",
    url: "/about/",
  },
  {
    id: 3,
    text: "Proyectos",
    url: "/projects/",
  },
  {
    id: 5,
    text: "Contáctame",
    url: "/contact/",
  },
]

export default data
