import React from 'react'
import  { ThemeToggler } from 'gatsby-plugin-dark-mode'
import { FaMoon, FaSun } from 'react-icons/fa'
import { IconContext } from "react-icons";

import Switch from "react-switch";

const EnableOrDisableDarkMode = ({setLogoImgPath, eagleDark, eagleLight}) => {
  const handleChange = (toggleTheme, val) => {
		toggleTheme(val ? 'dark' : 'light');
		// setLogoImgPath(val ? eagleLight : eagleDark)
  };

	return (
		<ThemeToggler>
			{({theme, toggleTheme}) => (
				<Switch
					height={32}
					width={62}
					offColor="#e0fcff"
					onColor="#2caeba"
					onChange={val => handleChange(toggleTheme, val) }
					checked={theme === 'dark'}
					className="react-switch"
					checkedIcon={
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
								fontSize: 16
							}}
						>
							<FaMoon />
						</div>
					}
					checkedHandleIcon={
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
								fontSize: 16
							}}
						>
							<FaSun />
						</div>
					}
					uncheckedIcon={
						<div
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
								fontSize: 16,
							}}
						>
							<IconContext.Provider value={{ color: "orange", className: "global-class-name" }}>
								<FaSun />
							</IconContext.Provider>
						</div>
					}
				/>
			)}
		</ThemeToggler>
	)
}

export default EnableOrDisableDarkMode