import React, { useEffect, useState, useCallback } from "react";
import { FaAngleUp } from "react-icons/fa"
import { motion } from "framer-motion"

export default function ScrollToTop() {
  const [isVisible, setIsVisible] = useState(false);
  const windowGlobal = typeof window !== 'undefined' && window;

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    windowGlobal.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  // Show button when page is scorlled upto given distance
  const toggleVisibility = useCallback(() => {
    setIsVisible(windowGlobal.pageYOffset > 300 ? true : false);
  }, [windowGlobal]);

  useEffect(() => {
    windowGlobal.addEventListener("scroll", toggleVisibility);
    return () => windowGlobal.removeEventListener("scroll", toggleVisibility);
  }, [windowGlobal, toggleVisibility]);

  return (
		<motion.div
			whileHover={{
				scale: 1.2,
				transition: { duration: 0.3 },
			}}
			whileTap={{ scale: 0.9 }}
			exit={{ scale: 1 }}
			className="scroll-to-top" style={{display: isVisible ? '' : 'none'}}
		>
			{
        isVisible &&
				<div role="button" tabIndex="-1" onClick={scrollToTop} onKeyDown={scrollToTop}>
					<FaAngleUp size={32} />
				</div>
			}
    </motion.div>
  );
}