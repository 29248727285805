import React from "react"
import { Link } from "gatsby"
import { FaTimes, FaGithub } from 'react-icons/fa'

import socialLinks from "../constants/social_links"
import links from "../constants/links"

import { Trans } from "gatsby-plugin-react-i18next"

const Sidebar = ({isOpen, toggleSidebar}) => {
  return (
    <aside className={isOpen ? "sidebar show-sidebar" : "sidebar"}>
      <button className="close-btn" onClick={toggleSidebar}>
        <FaTimes />
      </button>
      <div className="side-container">
        <ul className={isOpen ? "sidebar-links" : null}>
          {
            links.map((link) =>{
							return (
								<li 
                  key={link.id}
                >
									<Link to={link.url} onClick={toggleSidebar}>
                    <Trans>{link.text}</Trans>
                  </Link>
								</li>
							)
						})
          }
        </ul>
        <ul className={isOpen ? "social-links sidebar-links" : null}>
          <li>
            <a href="https://github.com/conka991" key="githubProfile" className="social-link">
              <FaGithub />
            </a>
          </li>
          {socialLinks.map((link) => {
            return <li key={link.id} rel="nofollow"><a href={link.url} className="social-link">{link.icon}</a></li>
          })}
        </ul>
      </div>
    </aside>
  )
}

export default Sidebar
