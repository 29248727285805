import React, { useEffect, useState } from "react"
import Select from 'react-select'
import { FaAlignRight } from "react-icons/fa"
import Flag from 'react-flagkit';

// import { Link } from "gatsby"
import { Link, useI18next, Trans } from "gatsby-plugin-react-i18next"

import { motion } from "framer-motion"
import "animate.css"

import EnableOrDisableDarkMode from "../components/EnableOrDisableDarkMode"
import pageLinks from "../constants/links"

const Navbar = ({ toggleSidebar }) => {
	const { languages, changeLanguage, language } = useI18next();
	const [ topNavbarClass, setTopNavbarClass ] = useState(null);
	const windowGlobal = typeof window !== 'undefined' && window;
	const [ eagleColorFill, setEagleColorFill ] = useState('#102a42');

	useEffect(() => {
		const elemToObserve = windowGlobal.document.body;
		let prevClassState = elemToObserve.classList.contains('dark');
		const observer = new MutationObserver(function(mutations) {
			mutations.forEach(function(mutation) {
				if (mutation.attributeName === "class") {
					let currentClassState = mutation.target.classList.contains('dark');

					if (prevClassState !== currentClassState) {
						prevClassState = currentClassState;
						setTimeout(() => {
							setEagleColorFill(currentClassState ? '#fff' : '#102a42');
						}, 400);
					}
				}
			});
		});
		observer.observe(elemToObserve, {attributes: true});
	}, [windowGlobal]);

	useEffect(() => {
		const onScroll = e => {
			if (windowGlobal.scrollY > 50) {
				const elemToObserve = windowGlobal.document.body;
				let prevClassState = elemToObserve.classList.contains('dark');
				const borderBottom = prevClassState ? '3px solid var(--clr-white)' : '3px solid var(--clr-primary-5)';

				setTopNavbarClass({
					borderBottom: borderBottom,
					boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
				})
			} else {
				setTopNavbarClass(null);
			}
    };
    windowGlobal.addEventListener("scroll", onScroll);

    return () => windowGlobal.removeEventListener("scroll", onScroll);
	}, [windowGlobal]);

	const variants = {
		visible: { opacity: 1 },
		hidden: { opacity: 0 },
	}

	const flagMap = {
		'es': <Flag country="CL" size={20} />,
		'en': <Flag country="US" size={20} />,
	}

  return (
    <nav id="topNavbar" style={ topNavbarClass }>
      <div className="nav-center">
        <div className="nav-header">
					<Link to="/">
						<div
							style={{cursor: 'pointer', display: 'flex', direction: 'row', alignItems: 'center', gap: '0.275rem'}}
						>
							{/* <img src={logoImgPath} alt="Eagle Logo" height="auto" width="auto" style={{verticalAlign: 'middle', margin: '0.25rem', height: "4rem", maxWidth: "100%"}} loading="lazy" /> */}
							<svg x="0px" y="0px" height="100%" width="100%" viewBox="0 0 463.305 463.305" style={{"enableBackground": "new 0 0 463.305 463.305", maxHeight: "4rem", maxWidth: "100%"}}>
								<path fill={eagleColorFill} d="M448.906,339.79c-0.719-4.659-3.701-8.622-7.977-10.599l-12.788-5.919c0.032-0.338,0.048-0.679,0.048-1.021
								c0-4.516-2.853-8.565-7.088-10.074c-39.039-14.016-62.598-25.48-76.759-33.963c2.076-10.154,9.185-18.475,19.067-22.057l1.071-0.394
								c11.381-4.117,20.178-13.039,24.133-24.479c3.956-11.439,2.549-23.89-3.86-34.16l-5.906-9.478L311.073,78.973
								c-1.111-1.78-3.447-2.44-5.373-1.361c-1.347,0.755-2.082,2.276-2.01,3.818l0.627,13.416L220.964,1.45
								c-0.964-1.081-2.422-1.662-3.843-1.379c-2.069,0.412-3.408,2.288-3.226,4.292c0.086,0.946,1.748,17.547,13.114,41.162
								c-9.492-10.09-20.051-21.776-28.049-31.89c-1.151-1.456-3.151-1.928-4.832-1.143c-1.681,0.786-2.601,2.624-2.222,4.44
								c1.794,8.61,7.009,28.253,20.245,50.561c-10.647-10.019-22.782-22.563-33.029-36.104c-1.169-1.544-3.277-2.03-5.002-1.151
								c-1.727,0.878-2.576,2.866-2.017,4.721c3.695,12.251,13.349,32.928,29.436,54.938c-10.203-8.016-21.988-17.82-33.004-28.286
								c-1.387-1.318-3.513-1.472-5.071-0.361c-1.56,1.107-2.117,3.164-1.33,4.907c3.612,8,14.278,29.073,35.249,51.919
								c1.098,1.196,2.211,2.367,3.328,3.532c-11.836-6.711-25.244-15.155-35.287-23.886c-1.478-1.283-3.667-1.31-5.174-0.063
								c-1.508,1.247-1.892,3.402-0.908,5.094c3.994,6.864,10.26,14.839,17.916,22.941l-17.762-8.215c-1.907-0.879-4.221-0.151-5.241,1.806
								c-0.714,1.368-0.496,3.041,0.409,4.291l7.883,10.887L41.492,101.969c-1.667-0.448-3.433,0.225-4.38,1.667
								c-0.947,1.442-0.863,3.33,0.209,4.682c0.75,0.946,11.197,13.819,33.329,27.198c-13.42-3.23-28.566-7.2-40.736-11.256
								c-1.757-0.585-3.692,0.113-4.667,1.692c-0.975,1.578-0.737,3.619,0.574,4.931c6.22,6.22,21.346,19.797,44.645,31.205
								c-14.394-2.547-31.428-6.396-47.416-12.105c-1.824-0.652-3.852,0.098-4.815,1.777c-0.963,1.681-0.584,3.81,0.9,5.054
								c9.8,8.221,29.179,20.228,54.683,29.823c-12.914-1.114-28.126-2.857-43.065-5.573c-1.882-0.347-3.743,0.696-4.44,2.477
								c-0.697,1.781-0.037,3.807,1.577,4.834c7.406,4.712,27.875,16.495,57.936,24.113c1.588,0.403,3.175,0.766,4.762,1.132
								c-13.589,0.874-29.46,1.158-42.649-0.639c-1.942-0.268-3.784,0.913-4.362,2.782c-0.578,1.87,0.283,3.883,2.033,4.759
								c12.099,6.049,32.576,11.725,56.457,14.229c-8.492,1.6-19.361,2.946-32.522,3.194c-2.017,0.038-3.689,1.571-3.902,3.578
								c-0.213,2.006,1.102,3.856,3.066,4.315c14.892,3.486,32.318,5.713,50.077,5.995c-8.335,2.966-17.27,5.706-23.155,6.266
								c-1.998,0.19-3.545,1.832-3.618,3.838c-0.073,2.005,1.352,3.754,3.331,4.089c6.58,1.112,13.608,1.667,21.066,1.667
								c11.497,0,24.015-1.325,37.465-3.958c-0.999,2.906-0.627,6.095,1.155,8.76c2.248,3.358,6.116,5.003,10.092,4.288l3.49-0.627
								c-1.252,2.899-1.063,6.238,0.635,9.072c2.194,3.66,6.315,5.495,10.507,4.681l2.924-0.57c-0.572,2.553-0.102,5.271,1.439,7.624
								c2.438,3.723,6.783,5.357,11.069,4.159l1.813-0.506c-0.12,0.373-0.215,0.751-0.289,1.131L16.737,374.345
								c-1.091,0.44-1.933,1.338-2.301,2.455c-0.369,1.117-0.228,2.34,0.386,3.343c1.204,1.967,30.015,48.239,76.534,53.965
								c0.163,0.021,0.327,0.03,0.489,0.03c0.971,0,1.916-0.354,2.651-1.005c19.009-16.827,52.014-40.13,82.373-60.551
								c17.336,3.517,34.23,5.548,50.319,6.059l-3.195,51.659c-0.104,1.683,0.858,3.25,2.406,3.919c0.512,0.221,1.051,0.328,1.585,0.328
								c1.082,0,2.145-0.439,2.917-1.262l10.869-11.575l17.542,7.653c0.063,0.028,0.19,0.077,0.19,0.077l27.001,10.166l-4.095,18.719
								c-0.156,0.713-0.167,1.463,0.054,2.158c0.553,1.738,2.126,2.82,3.826,2.82H300.7c2.089,0,3.955-1.527,4.153-3.607
								c0.228-2.386-1.642-4.393-3.981-4.393h-9.614l3.111-14.223h12.069l7.037,9.97c1.205,1.707,3.528,2.351,5.342,1.315
								c2.081-1.189,2.643-3.875,1.294-5.786l-8.333-11.806c-0.75-1.062-1.968-1.693-3.268-1.693h-10.889l0.726-1.452l11.929-6.743
								l11.07,13.982c0.79,0.998,1.958,1.518,3.139,1.518c0.898,0,1.803-0.301,2.552-0.923c1.697-1.407,1.833-3.979,0.465-5.707
								l-13.078-16.519c-1.223-1.545-3.39-1.971-5.104-1l-14.178,8.015l-26.706-12.477l16.681-1.714c2.044-0.211,3.659-1.954,3.591-4.141
								c-0.042-1.343-0.835-2.541-1.956-3.281c-9.326-6.166-16.054-15.434-19.105-26.047c8.628-1.229,16.905-2.963,24.724-5.175
								l-1.704,27.458c-0.098,1.581,0.648,3.152,2.034,3.919c0.615,0.341,1.284,0.505,1.947,0.505c1.083,0,2.145-0.439,2.918-1.263
								l10.869-11.582l17.543,7.661c0.063,0.028,0.129,0.045,0.193,0.069l-0.001,0.004l27,10.166l-4.095,18.719
								c-0.156,0.713-0.167,1.463,0.054,2.158c0.553,1.738,2.126,2.82,3.826,2.82h14.411c2.089,0,3.955-1.527,4.153-3.607
								c0.228-2.386-1.643-4.393-3.981-4.393h-9.613l3.111-14.223h12.069l7.038,9.97c1.205,1.707,3.528,2.351,5.342,1.314
								c2.081-1.189,2.643-3.875,1.294-5.786l-8.333-11.806c-0.75-1.062-1.968-1.693-3.268-1.693h-10.889l0.726-1.451l11.929-6.743
								l11.07,13.982c0.79,0.998,1.958,1.518,3.139,1.518c0.898,0,1.803-0.301,2.552-0.923c1.697-1.407,1.833-3.979,0.465-5.707
								l-13.078-16.52c-1.223-1.546-3.389-1.971-5.104-1l-14.178,8.015l-26.701-12.474l16.681-1.719c2.044-0.21,3.659-1.955,3.591-4.143
								c-0.042-1.342-0.836-2.539-1.956-3.279c-6.247-4.127-11.324-9.644-14.941-16.108c4.924-0.769,11.268-1.623,19.466-2.545
								c14.05-1.591,28.305,2.024,40.146,10.173c5.699,3.914,11.362,6.788,16.833,8.542c0.403,0.129,0.814,0.19,1.221,0.19
								c1.53,0,2.989-0.882,3.658-2.377c0.693-1.55,0.243-3.379-0.957-4.58l-17.265-17.271l17.695,2.287
								c3.404,0.437,6.212,3.127,6.621,6.92c0.108,1.007-0.122,2.007-0.399,2.981c-0.537,1.891,0.379,3.935,2.25,4.759
								c1.479,0.652,3.216,0.27,4.41-0.819l12.332-11.244C447.974,349.124,449.624,344.449,448.906,339.79z M382.413,213.229
								c0.815,5.107,0.382,10.396-1.362,15.44c-3.163,9.146-10.195,16.279-19.313,19.577l-1.074,0.395
								c-11.556,4.19-20.12,13.512-23.438,25.052c-4.822-3.273-7.992-5.93-10.068-7.943c0.804-4.906,2.279-9.541,4.31-13.504
								c4.83-9.428,13.645-12.212,22.977-15.16c5.988-1.892,12.181-3.848,17.421-7.645C377.733,225.188,380.82,219.06,382.413,213.229z
								M180.36,82.936c21.516,18.276,41.465,32.047,43.745,33.608c11.924,12.143,26.718,24.493,44.244,34.038
								c0.607,0.331,1.263,0.488,1.91,0.488c1.417,0,2.791-0.755,3.516-2.088c1.057-1.939,0.341-4.369-1.6-5.426
								c-39.467-21.497-67.24-58.996-81.896-86.616c19.599,21.029,39.321,36.485,43.055,39.354c10.18,11.312,22.746,22.424,38.255,32.35
								c0.668,0.428,1.415,0.632,2.152,0.631c1.318,0,2.609-0.651,3.373-1.844c1.191-1.86,0.648-4.335-1.213-5.525
								c-23.434-14.997-42.468-34.567-56.576-58.167c-6.811-11.395-11.416-22.036-14.483-30.655c17.352,19.757,37.666,39.928,39.999,42.235
								c8.62,11.938,18.8,23.372,30.27,33.986c1.822,1.689,4.796,1.353,6.161-0.894c0.992-1.634,0.589-3.758-0.813-5.057
								c-11.134-10.314-21.003-21.413-29.333-32.99c-13.657-18.986-20.886-36.114-24.544-47.14c-0.625-1.883-1.169-3.656-1.645-5.309
								l80.917,90.667c1.365,1.529,3.712,1.817,5.445,0.496c1.061-0.809,1.591-2.142,1.529-3.475l-0.439-9.404l63.55,101.898
								c0.224,3.61,0.502,18.145-8.767,24.862c-4.191,3.037-9.507,4.717-15.136,6.495c-10,3.158-21.333,6.738-27.687,19.14
								c-1.918,3.742-3.404,7.965-4.408,12.42c-1.403,0.389-2.496,1.507-2.829,2.94c-1.513,6.515-2.79,12.738-3.837,18.684
								c-9.604-3.773-16.753-12.302-18.523-23.041c-1.334-8.092,0.948-16.367,5.872-22.927c6.797-9.056,9.817-20.532,7.77-31.668
								c-2.373-12.91-10.722-23.62-22.512-29.073l-11.887-5.498c1.316-0.231,2.489-1.111,3.024-2.442c0.825-2.05-0.168-4.38-2.218-5.204
								C230.982,145.157,198.843,112.085,180.36,82.936z M292.725,190.664c3.491,3.831,6.034,8.501,7.342,13.686
								c2.367,9.384,0.394,19.206-5.413,26.948l-0.698,0.93c-7.682,10.242-9.662,23.543-5.297,35.578
								c3.941,10.866,12.52,19.192,23.318,22.872c-0.124,0.849-0.245,1.694-0.359,2.531c-24.856-3.16-38.386-25.94-40.104-41.97
								c-1.128-10.531,4.72-17.691,10.911-25.272c3.97-4.861,8.076-9.889,10.378-15.933C295.385,203.26,294.598,196.427,292.725,190.664z
								M183.552,120.72c18.63,11.74,37.354,20.559,40.667,22.094c6.848,5.306,13.962,10.134,21.331,14.466l-45.636-21.106
								C194.023,131.14,188.482,125.896,183.552,120.72z M193.036,293.417c-1.28,0.357-1.982-0.466-2.225-0.836
								c-0.242-0.371-0.716-1.344,0.124-2.375l3.316-4.069c1.064-1.306,1.196-3.138,0.33-4.582c-0.866-1.446-2.546-2.191-4.197-1.87
								l-12.162,2.373c-1.251,0.238-1.895-0.577-2.112-0.941c-0.219-0.364-0.64-1.318,0.166-2.308l4.781-5.867
								c1.057-1.297,1.195-3.115,0.345-4.557c-0.849-1.441-2.504-2.197-4.153-1.907l-13.54,2.431c-1.17,0.21-1.81-0.532-2.029-0.861
								c-0.22-0.33-0.663-1.203-0.023-2.204l2.82-4.407c2.291-0.544,4.604-1.12,6.944-1.736c21.832-5.74,37.337-12.417,37.986-12.698
								c2.027-0.878,2.958-3.232,2.08-5.26c-0.878-2.026-3.232-2.958-5.26-2.081c-0.514,0.223-43.512,18.605-85.379,19.93
								c10.374-3.653,19.756-7.644,22.086-8.648c18.221-1.683,35.721-5.777,50.034-13.074c1.968-1.003,2.75-3.412,1.747-5.38
								c-1.002-1.969-3.41-2.752-5.38-1.747c-18.523,9.443-50.937,15.734-88.063,12.3c14.467-2.517,23.074-5.889,25.557-6.951
								c0.26,0.001,0.518,0.005,0.778,0.005c19.469-0.001,39.927-2.387,59.18-8.389c2.109-0.657,3.286-2.899,2.628-5.009
								c-0.658-2.109-2.899-3.289-5.009-2.628c-37.489,11.687-79.573,9.168-108.948,2.216c22.21-0.374,42.934-3.301,46.426-3.817
								c5.368,0.425,10.722,0.664,16.055,0.664c13.046-0.001,25.979-1.273,38.72-3.822c2.166-0.433,3.571-2.54,3.138-4.706
								c-0.434-2.166-2.539-3.578-4.707-3.138c-46.314,9.261-91.319-0.789-122.755-15.044c27.936,3.487,52.119,4.082,54.989,4.142
								c12.846,2.8,27.191,4.766,42.231,4.766c4.431,0,8.923-0.17,13.45-0.539c2.202-0.179,3.841-2.109,3.662-4.311
								c-0.18-2.202-2.121-3.843-4.311-3.663c-44.793,3.651-88.574-12.502-115.968-27.575c27.845,6.833,52.752,8.96,57.542,9.323
								c14.163,3.741,30.078,6.112,47.673,6.111c0.691,0,1.396-0.004,2.092-0.011c2.209-0.023,3.981-1.834,3.958-4.043
								c-0.023-2.194-1.81-3.957-3.999-3.957c-0.015,0-0.029,0-0.043,0c-27.799,0.296-54.466-5.643-79.2-17.653
								c-11.941-5.799-21.625-12.177-28.914-17.706c24.801,6.868,52.19,12.513,56.363,13.362c12.672,4.845,27.352,9.056,44.185,11.887
								c2.176,0.37,4.241-1.103,4.608-3.28c0.367-2.179-1.102-4.242-3.281-4.608c-37.485-6.307-63.702-19.921-79.093-30.231
								c-5.809-3.892-10.556-7.617-14.28-10.826l117.367,31.504c1.915,0.519,3.966-0.45,4.751-2.373c0.535-1.309,0.267-2.813-0.563-3.958
								l-5.511-7.61l104.374,48.273c3.406,1.575,6.237,4.186,8.013,7.492c2.805,5.219,5.647,13.043,2.901,20.251
								c-1.842,4.834-5.367,9.15-9.099,13.72c-6.634,8.124-14.153,17.33-12.668,31.185c1.21,11.299,6.584,22.954,14.745,31.979
								c4.169,4.611,10.272,9.816,18.514,13.346l-27.336,30.312c-0.815,0.904-1.79,0.683-2.172,0.548c-0.382-0.137-1.276-0.585-1.333-1.801
								c-0.066-1.396-0.858-2.657-2.086-3.324c-1.229-0.668-2.717-0.645-3.925,0.06l-5.721,3.338c-0.993,0.579-1.834,0.138-2.15-0.077
								c-0.316-0.216-1.032-0.841-0.853-1.977l0.107-0.677c0.216-1.366-0.29-2.747-1.337-3.651c-1.046-0.903-2.486-1.204-3.808-0.791
								l-9.049,2.828c-1.138,0.357-1.863-0.303-2.123-0.604c-0.259-0.3-0.808-1.112-0.294-2.187l1.684-3.522
								c0.722-1.51,0.427-3.31-0.738-4.511c-1.165-1.202-2.956-1.551-4.486-0.874l-7.982,3.521c-1.178,0.519-1.987-0.151-2.281-0.464
								c-0.293-0.313-0.912-1.162-0.32-2.305l1.859-3.586c0.75-1.445,0.548-3.201-0.51-4.439c-1.058-1.238-2.762-1.712-4.306-1.196
								l-10.458,3.485c-1.231,0.41-1.974-0.344-2.236-0.688c-0.262-0.344-0.795-1.26-0.075-2.339l4.167-6.251
								c0.928-1.392,0.892-3.213-0.09-4.567c-0.982-1.354-2.704-1.952-4.313-1.504L193.036,293.417z M217.185,320.735l-70.243,38.293
								c-2.208-2.405-4.054-4.956-5.59-7.494l72.017-35.021c0.439,0.903,1.015,1.757,1.738,2.527
								C215.736,319.711,216.438,320.273,217.185,320.735z M198.568,309.625c0.016,0.021,0.028,0.044,0.044,0.065
								c1.089,1.426,2.476,2.486,4.017,3.149l-64.905,31.563c-1.53-3.619-2.463-6.819-2.999-9.024L198.568,309.625z M24.325,379.91
								l102.921-41.515c0.669,2.53,1.712,5.839,3.288,9.504l-94.675,46.04C30.612,388.319,26.699,383.227,24.325,379.91z M41.811,399.941
								l92.331-44.9c1.558,2.656,3.401,5.34,5.586,7.921l-86.054,46.913C49.322,406.687,45.354,403.3,41.811,399.941z M90.576,425.928
								c-10.869-1.625-20.736-5.78-29.397-11.034l84.447-46.037c3.348,2.824,7.257,5.322,11.808,7.26
								C132.131,393.473,106.814,411.779,90.576,425.928z M165.634,370.524c-4.779-1.336-8.856-3.38-12.325-5.855l74.061-40.375
								c0.401,0.902,0.94,1.76,1.619,2.544c0.412,0.475,0.867,0.887,1.34,1.268C216.427,336.942,191.567,352.932,165.634,370.524z
								M289.901,430.634l-0.717,1.434l-24.515-9.23l-0.377-4.168L289.901,430.634z M295.494,362.134
								c-9.117,2.932-19.002,5.145-29.429,6.602l-2.815-20.156c-0.306-2.188-2.329-3.702-4.515-3.408c-2.188,0.306-3.714,2.327-3.408,4.516
								l3.013,21.574c1.708,12.229,7.484,23.413,16.242,31.841l-15.618,1.604c-2.179,0.225-3.772,2.158-3.575,4.34l0.93,10.283
								l-13.936-6.08c-1.55-0.677-3.357-0.304-4.516,0.928l-5.207,5.545l3.621-58.545c0.137-2.205-1.541-4.104-3.745-4.239
								c-2.212-0.158-4.103,1.54-4.239,3.745l-0.617,9.968c-13.267-0.408-27.119-1.892-41.331-4.413
								c27.438-18.259,51.086-33.191,57.738-37.368c0.737,1.658,1.913,3.124,3.494,4.204c3.225,2.205,7.323,2.352,10.696,0.383l1.736-1.013
								c1.149,1.51,2.73,2.693,4.607,3.364c1.109,0.396,2.252,0.588,3.382,0.588c2.779,0,5.483-1.162,7.422-3.313l29.695-32.928
								c1.775,0.418,3.624,0.756,5.552,0.997c-2.221,21.994-0.497,39.328,5.177,52.42C312.322,355.536,305.56,358.888,295.494,362.134z
								M356.568,399.384l-0.717,1.434l-24.515-9.23l-0.377-4.168L356.568,399.384z M341.244,371.853l-15.616,1.609
								c-2.179,0.224-3.772,2.159-3.574,4.341l0.931,10.277l-13.937-6.086c-1.549-0.675-3.359-0.304-4.518,0.929l-5.203,5.545l1.215-19.588
								c8.973-3.069,15.28-6.16,18.937-8.174c3.321-1.828,6.878-3.173,10.591-3.937l0.119-0.024
								C332.991,362.409,336.722,367.509,341.244,371.853z M439.104,346.385l-6.125,5.584c-0.391-1.09-0.906-2.144-1.542-3.141
								c-2.491-3.899-6.55-6.474-11.135-7.063l-29.33-3.79c-0.172-0.022-0.344-0.033-0.514-0.033c-1.509,0-2.909,0.854-3.588,2.234
								c-0.755,1.536-0.45,3.383,0.76,4.594l6.615,6.617c-11.523-5.715-24.428-8.046-37.193-6.601c-16.155,1.815-27.534,3.637-33.948,5.431
								c-7.277-16.912-7.223-42.234,0.167-77.097c20.63,18.539,72.237,38.368,95.136,46.59c1.231,0.438,1.782,1.592,1.782,2.541
								c0,0.386-0.077,0.754-0.229,1.092c-0.016,0.036-0.032,0.072-0.047,0.109l-0.027,0.053c-0.012,0.021-0.024,0.042-0.035,0.063
								c-0.642,1.187-2.088,1.71-3.354,1.223l-40.57-15.739c-2.06-0.799-4.377,0.223-5.176,2.283c-0.799,2.06,0.223,4.376,2.282,5.176
								l16.658,6.462l1.526,6.725c0.954,4.206,3.952,7.574,8.02,9.009c1.366,0.481,2.775,0.718,4.172,0.718
								c2.763,0,5.479-0.925,7.726-2.703l4.889-3.87c3.052,0.415,6.132-0.493,8.437-2.468l13.111,6.069
								c1.866,0.862,3.116,2.523,3.429,4.558C441.313,343.042,440.622,345.001,439.104,346.385z M407.087,329.722l-0.92,0.728
								c-1.232,0.975-2.79,1.235-4.271,0.712c-1.482-0.522-2.531-1.701-2.879-3.233l-0.333-1.467L407.087,329.722z"/>
							</svg>
							<motion.span className="change-to-white-on-dark" initial="hidden" animate="visible" variants={variants}  transition={{ duration: 0.8 }} style={{color: 'var(--clr-primary-1)', fontWeight: 900, fontSize: '3rem', fontFamily: "'Lobster', cursive"}}>K</motion.span>
							<motion.span className="change-to-white-on-dark" initial="hidden" animate="visible" variants={variants}  transition={{ duration: 0.8 }} style={{color: 'var(--clr-primary-1)', fontWeight: 900, fontSize: '2rem', fontFamily: "'Lobster', cursive"}}>r</motion.span>
							<motion.span className="change-to-white-on-dark" initial="hidden" animate="visible" variants={variants}  transition={{ duration: 0.7 }} style={{color: 'var(--clr-primary-2)', fontWeight: 900, fontSize: '2rem', fontFamily: "'Lobster', cursive"}}>i</motion.span>
							<motion.span className="change-to-white-on-dark" initial="hidden" animate="visible" variants={variants}  transition={{ duration: 0.7 }} style={{color: 'var(--clr-primary-2)', fontWeight: 900, fontSize: '2rem', fontFamily: "'Lobster', cursive"}}>s</motion.span>
							<motion.span className="change-to-white-on-dark" initial="hidden" animate="visible" variants={variants}  transition={{ duration: 0.7 }} style={{color: 'var(--clr-primary-3)', fontWeight: 900, fontSize: '2rem', fontFamily: "'Lobster', cursive"}}>t</motion.span>
							<motion.span className="change-to-white-on-dark" initial="hidden" animate="visible" variants={variants}  transition={{ duration: 0.7 }} style={{color: 'var(--clr-primary-3)', fontWeight: 900, fontSize: '2rem', fontFamily: "'Lobster', cursive"}}>i</motion.span>
							<motion.span className="change-to-white-on-dark" initial="hidden" animate="visible" variants={variants}  transition={{ duration: 0.7 }} style={{color: 'var(--clr-primary-4)', fontWeight: 900, fontSize: '2rem', fontFamily: "'Lobster', cursive"}}>á</motion.span>
							<motion.span className="change-to-white-on-dark" initial="hidden" animate="visible" variants={variants}  transition={{ duration: 0.7 }} style={{color: 'var(--clr-primary-4)', fontWeight: 900, fontSize: '2rem', fontFamily: "'Lobster', cursive"}}>n</motion.span>
						</div>
					</Link>
          <button type="button" className="toggle-btn" onClick={toggleSidebar}>
            <FaAlignRight />
          </button>
        </div>
        <div className="nav-links">
          {pageLinks.map((link, index) => {
						const animationClasses = index % 2 ? "animate__animated animate__fadeInBottomLeft animate__delay-0.2" :
							"animate__animated animate__fadeInBottomRight animate__delay-0.2";

            return (
							link.url === '/' ?
								<Link
									id={link.id}
									key={link.id}
									to={link.url}
									className={animationClasses}
									style={{textTransform:"unset"}}
								>
									<Trans>{link.text}</Trans>
								</Link> :
								<Link
									id={link.id}
									key={link.id}
									to={link.url}
									className={animationClasses}
									style={{textTransform:"unset"}}
								>
									<Trans>{link.text}</Trans>
								</Link>
						)
          })}
        </div>
				<div className="global-page-actions">
					<EnableOrDisableDarkMode
						// setLogoImgPath={setLogoImgPath}
						// eagleDark={eagleDark}
						// eagleLight={eagleLight}
					/>
					<div style={{width: '100px'}}>
						<Select
							className='react-select-container'
							classNamePrefix="react-select"
							defaultMenuIsOpen={false}
							isSearchable={false}
							options={
								languages.map((lng) => {
									return {
										value: lng, label: (
										<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center !important', gap: '0.375rem'}}>
											{flagMap[lng]}
											<p style={{marginBottom: 0}}>{lng}</p>
										</div>
									)}
								})
							}
							value={{
								value: language,
								label:  (
									<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center !important', gap: '0.375rem'}}>
										{flagMap[language]}
										<p style={{marginBottom: 0}}>{language}</p>
									</div>
								)
							}}
							onChange={(selected) => {
								changeLanguage(selected.value);
							}}
						/>
					</div>
				</div>
      </div>
    </nav>
  )
}

export default Navbar